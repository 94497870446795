import {
  Switch as MantineSwitch,
  type SwitchProps as MantineSwitchProps,
} from '@mantine/core'
import { forwardRef } from 'react'

export type KuiSwitchProps = Pick<
  MantineSwitchProps,
  'label' | 'description' | 'error' | 'disabled' | 'labelPosition'
> & {
  checked: boolean
  onChange?: (nextChecked: boolean) => void
}

export const KuiSwitch = forwardRef<HTMLInputElement, KuiSwitchProps>(
  ({ onChange, ...restProps }, ref) => {
    return (
      <MantineSwitch
        ref={ref}
        {...restProps}
        onChange={
          onChange ? (event) => onChange(event.target.checked) : undefined
        }
      />
    )
  }
)
