import {
  Checkbox as MantineCheckbox,
  type CheckboxProps as MantineCheckboxProps,
} from '@mantine/core'
import { forwardRef } from 'react'

export type KuiCheckboxProps = Pick<
  MantineCheckboxProps,
  | 'label'
  | 'description'
  | 'placeholder'
  | 'error'
  | 'disabled'
  | 'indeterminate'
> & {
  checked: boolean
  onChange?: (nextChecked: boolean) => void
}

export const KuiCheckbox = forwardRef<HTMLInputElement, KuiCheckboxProps>(
  ({ onChange, ...restProps }, ref) => {
    return (
      <MantineCheckbox
        ref={ref}
        {...restProps}
        onChange={
          onChange ? (event) => onChange(event.target.checked) : undefined
        }
      />
    )
  }
)
