import { Table as MantineTable, Skeleton, Transition } from '@mantine/core'
import classNames from 'classnames'
import { omit, sumBy } from 'lodash-es'
import {
  Fragment,
  useEffect,
  useMemo,
  useState,
  type Key,
  type ReactNode,
} from 'react'
import styled, { css } from 'styled-components'

import {
  kuiThemeVars,
  type KuiThemeSpacingSize,
} from 'components/kui/_internal/theme'
import { KuiCheckbox } from 'components/kui/inputs'
import { KuiActionList, type KuiAction } from 'components/kui/KuiActionList'
import { KuiButton } from 'components/kui/KuiButton'
import {
  KuiFlex,
  mixKuiFlex,
  type KuiFlexJustifyContent,
} from 'components/kui/KuiFlex'
import { KuiMenu } from 'components/kui/KuiMenu'
import { KuiPad, type MixKuiPadProps } from 'components/kui/KuiPad'
import { KuiText, mixKuiText } from 'components/kui/KuiText'
import { KuiTooltip } from 'components/kui/KuiTooltip'
import { useKuiMediaQuery } from 'components/kui/useKuiMediaQuery'

import { KuiPagination, type KuiPaginationProps } from './KuiPagination'
import { KuiStackedList, type KuiStackedListProps } from './KuiStackedList'

type KuiTableColumnWidth = 's' | 'm' | 'l' | 'money'

const widthMap: Record<KuiTableColumnWidth, number> = {
  s: 80,
  m: 100,
  l: 200,
  money: 115,
}

type KuiTableCellAlignment = 'left' | 'center' | 'right'

export type KuiTableColumn<TRow> = {
  /** @default the value of `label` */
  key?: string

  label: string

  align?: KuiTableCellAlignment

  /** @default false */
  numeric?: boolean

  /** @default 'm' */
  width?: KuiTableColumnWidth | number

  /** @default true */
  grow?: boolean

  /** @default false */
  interactive?: boolean

  /** @default false */
  sticky?: boolean

  renderCell: (row: TRow, _: { hovered: boolean }) => ReactNode

  /** @default true */
  visible?: boolean

  _extraHeaderPadding?: MixKuiPadProps

  _allowHeaderOverflow?: boolean

  _renderHeader?: () => ReactNode
}

const KuiTableWrapperRoot = styled.div`
  --kui-table-border-color: var(--mantine-color-gray-3);

  position: relative;

  &.KuiTableWrapperRoot--variant--surface {
    border-radius: 8px;
    border: 1px solid var(--kui-table-border-color);
    overflow: hidden;
    background-color: ${kuiThemeVars.colors.emptyShade};
  }
`

type KuiTableRootProps = {
  $gridTemplateColumns: string
  $gutterSize: KuiThemeSpacingSize
  $fixedRowCount: number | null
  $rowHeight: string
  $needsBottomBorder: boolean
}

const KuiTableRoot = styled(MantineTable)<KuiTableRootProps>`
  border-spacing: 0;
  border-collapse: separate;
  overflow: hidden;

  th:first-child,
  td:first-child {
    padding-left: ${(p) => kuiThemeVars.spacingSizes[p.$gutterSize]};
  }

  th:last-child,
  td:last-child {
    padding-right: ${(p) => kuiThemeVars.spacingSizes[p.$gutterSize]};
  }

  &.KuiTableRoot--variant--flush {
    border-top: 1px solid var(--kui-table-border-color);
  }

  & thead th {
    background-color: var(--mantine-color-gray-2);
  }

  ${(p) =>
    p.$needsBottomBorder
      ? 'box-shadow: inset 0 -1px 0 0 var(--kui-table-border-color);'
      : `tr:last-child td {
          border-bottom-color: transparent !important;
        }
      `}

  overflow-x: auto;
  display: grid;
  grid-template-columns: auto;

  & thead {
    display: grid;
    grid-template-rows: 35px;
    grid-template-columns: ${(p) => p.$gridTemplateColumns};
    ${mixKuiText({ size: 'xs' })}
    color: var(--mantine-color-dark-3);
  }

  & tbody {
    display: grid;
    grid-template-rows: ${(p) =>
      p.$fixedRowCount ? `repeat(${p.$fixedRowCount}, ${p.$rowHeight})` : null};
    grid-auto-rows: ${(p) => p.$rowHeight};
    grid-template-columns: ${(p) => p.$gridTemplateColumns};
  }

  & tr {
    display: contents;
  }

  & th,
  td {
    overflow: hidden;
    border-bottom: 1px solid var(--kui-table-border-color);
    background-color: ${kuiThemeVars.colors.emptyShade};
  }

  &.KuiTableRoot--empty {
    overflow-x: hidden;
  }
`

const alignmentToFlexJustifyContent: Record<
  KuiTableCellAlignment,
  KuiFlexJustifyContent
> = {
  left: 'flexStart',
  center: 'center',
  right: 'flexEnd',
}

type StickyColumnProps =
  | {
      left: number
      borderRight: boolean
    }
  | {
      right: number
      borderLeft: boolean
    }

type MixCellStylesParams = {
  $numeric?: boolean
  $align?: KuiTableCellAlignment
  $cursor?: string
  $stickyProps?: StickyColumnProps
}

function mixTableCellStyles({
  $numeric = false,
  $align = $numeric ? 'right' : 'left',
  $cursor,
  $stickyProps,
}: MixCellStylesParams) {
  return css`
    ${$numeric && 'font-variant-numeric: tabular-nums;'}
    ${mixKuiFlex({
      justifyContent: alignmentToFlexJustifyContent[$align],
    })}
  ${$cursor && `cursor: ${$cursor};`}
  ${$stickyProps &&
    'left' in $stickyProps &&
    `position: sticky; left: ${$stickyProps.left}px; z-index: ${kuiThemeVars.stackingLevels.sticky}; ${$stickyProps.borderRight ? 'border-right: 2px solid var(--kui-table-border-color);' : ''}`}
  ${$stickyProps &&
    'right' in $stickyProps &&
    `position: sticky; right: ${$stickyProps.right}px; z-index: ${kuiThemeVars.stackingLevels.sticky};
    ${$stickyProps.borderLeft ? 'border-left: 2px solid var(--kui-table-border-color);' : ''}
    `}
  `
}

const KuiTableHeaderCell = styled(MantineTable.Th)<MixCellStylesParams>`
  ${mixTableCellStyles}
`

const KuiTableCell = styled(MantineTable.Td)<MixCellStylesParams>`
  ${mixTableCellStyles}
`

const KuiTableRow = styled(MantineTable.Tr)`
  &.KuiTableRow--clickable:hover td {
    background-color: var(--mantine-color-gray-1);
  }
`

// todo come up with better names
type KuiTableVariant = 'surface' | 'flush'

type KuiTableGutterSize = 'normal' | 'modal' | KuiThemeSpacingSize

const gutterSizeMap: Record<KuiTableGutterSize, KuiThemeSpacingSize> = {
  normal: 'xs',
  modal: 'md',

  none: 'none',
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
}

type KuiRowHeight = 's' | 'm' | 'l'

const rowHeightMap: Record<KuiRowHeight, string> = {
  s: '40px',
  m: '50px',
  l: '64px',
}

type KuiTableEmptyStateRootProps = {
  $height: string
  $needsBottomBorder: boolean
}

const KuiTableEmptyStateRoot = styled.div<KuiTableEmptyStateRootProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(p) => p.$height};
  ${(p) =>
    p.$needsBottomBorder &&
    'box-shadow: inset 0 -1px 0 0 var(--kui-table-border-color);'}
`

export type KuiTablePaginationProps = KuiPaginationProps & {
  visible: boolean
}

export type KuiTableBulkActionContext<TRow> = {
  selectedRows: TRow[]
  selectedAllAcrossPages: boolean
  clearSelection: () => void
}

type KuiTableBulkActionsGetter<TRow> = (_: KuiTableBulkActionContext<TRow>) => {
  customComponent?: ReactNode
  actions: KuiAction[]
}

export type KuiTableProps<TRow> = {
  rows: TRow[]
  columns: KuiTableColumn<TRow>[]
  getRowKey: (row: TRow) => Key

  onRowClick?: (row: TRow) => void

  /** @default () => true */
  getRowIsClickable?: (row: TRow) => boolean

  /** @default false */
  loading?: boolean

  /** @default 'surface' */
  variant?: KuiTableVariant

  /** @default 'normal' */
  gutterSize?: KuiTableGutterSize

  /** @default 'm' */
  rowHeight?: KuiRowHeight

  /** @default 5 */
  numLoadingRows?: number

  /** @default 1 */
  minRows?: number

  /** @default 'Nothing found' */
  emptyState?: ReactNode

  pagination?: KuiTablePaginationProps

  bulkActions?: KuiTableBulkActionsGetter<TRow>

  renderMobileRow?: KuiStackedListProps<TRow>['renderRow']
}

export function KuiTable<TRow>({
  rows,
  columns: consumerColumns,
  getRowKey,
  onRowClick,
  getRowIsClickable = () => true,
  pagination: consumerPagination,
  loading,
  bulkActions,
  variant = 'surface',
  gutterSize = 'normal',
  rowHeight = 'm',
  numLoadingRows = 5,
  minRows: consumerMinRows = 1,
  emptyState = 'Nothing found',
  renderMobileRow,
}: KuiTableProps<TRow>) {
  const [selectedRows, setSelectedRows] = useState<TRow[]>([])
  const [selectedAllAcrossPages, setSelectedAllAcrossPages] = useState(false)

  const selectedRowKeys = useMemo(
    () => new Set(selectedRows.map(getRowKey)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRows]
  )

  useEffect(() => {
    if (rows.length === 0) {
      setSelectedAllAcrossPages(false)
    }

    if (selectedAllAcrossPages) {
      setSelectedRows(rows)
    } else {
      const rowKeys = new Set(rows.map(getRowKey))
      setSelectedRows((prev) =>
        prev.filter((row) => rowKeys.has(getRowKey(row)))
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, selectedAllAcrossPages])

  const isMobile = !!useKuiMediaQuery({ maxWidth: 'sm' })

  const [hovered, setHovered] = useState<Key | null>(null)

  const selectable = !!bulkActions

  const columns = consumerColumns.filter((column) => column.visible !== false)

  const { processedColumns, checkboxStickyProps } = useMemo(
    () =>
      getProcessedColumns({
        columns,
        hasCheckboxColumn: selectable,
        isMobile,
      }),
    [columns, isMobile, selectable]
  )

  const pagination = consumerPagination?.visible
    ? consumerPagination
    : undefined

  const minRows = Math.max(consumerMinRows, 1)

  const fixedRowCount = getFixedRowCount()

  const headerCheckboxProps = getHeaderCheckboxProps()

  if (isMobile && renderMobileRow) {
    return (
      <KuiStackedList
        rows={rows}
        getRowKey={getRowKey}
        renderRow={renderMobileRow}
        pagination={pagination}
        variant={variant}
        loading={loading}
        numLoadingRows={numLoadingRows}
        emptyState={emptyState}
        getRowIsClickable={getRowIsClickable}
        onRowClick={onRowClick}
        gutterSize={gutterSizeMap[gutterSize]}
      />
    )
  }

  return (
    <KuiTableWrapperRoot
      className={classNames({
        'KuiTableWrapperRoot--variant--surface': variant === 'surface',
      })}
    >
      <KuiTableRoot
        $gridTemplateColumns={getGridTemplateColumns({
          processedColumns,
          hasCheckboxColumn: selectable,
        })}
        $gutterSize={gutterSizeMap[gutterSize]}
        $fixedRowCount={fixedRowCount}
        $rowHeight={rowHeightMap[rowHeight]}
        $needsBottomBorder={!!pagination}
        className={classNames({
          'KuiTableRoot--variant--flush': variant === 'flush',
          'KuiTableRoot--empty': rows.length === 0,
        })}
      >
        <MantineTable.Thead>
          <KuiTableRow>
            {selectable && (
              <KuiTableHeaderCell
                $align='center'
                $stickyProps={checkboxStickyProps}
              >
                {!loading && rows.length > 0 && (
                  <KuiTooltip content={headerCheckboxProps.tooltipContent}>
                    <KuiCheckbox
                      {...omit(headerCheckboxProps, 'tooltipContent')}
                    />
                  </KuiTooltip>
                )}
              </KuiTableHeaderCell>
            )}

            {processedColumns.map(({ column, stickyProps }) => (
              <KuiTableHeaderCell
                key={column.key ?? column.label}
                $numeric={column.numeric}
                $align={column.align}
                $stickyProps={stickyProps}
                style={
                  column._allowHeaderOverflow ? { overflow: 'visible' } : {}
                }
              >
                {column._extraHeaderPadding ? (
                  <KuiPad {...column._extraHeaderPadding}>
                    {column._renderHeader?.() ?? (
                      <KuiText.p truncate={true}>{column.label}</KuiText.p>
                    )}
                  </KuiPad>
                ) : (
                  (column._renderHeader?.() ?? (
                    <KuiText.p truncate={true}>{column.label}</KuiText.p>
                  ))
                )}
              </KuiTableHeaderCell>
            ))}
          </KuiTableRow>
        </MantineTable.Thead>

        <MantineTable.Tbody>
          {loading && (
            <KuiTableLoadingRows
              numRows={numLoadingRows}
              processedColumns={processedColumns}
              selectable={selectable}
              checkboxStickyProps={checkboxStickyProps}
            />
          )}

          {!loading &&
            rows.map((row) => {
              const rowKey = getRowKey(row)

              return (
                <KuiTableRow
                  key={rowKey}
                  className={classNames({
                    'KuiTableRow--clickable':
                      !!onRowClick && getRowIsClickable(row),
                  })}
                  onPointerEnter={() => setHovered(rowKey)}
                  onPointerLeave={() => setHovered(null)}
                >
                  {selectable && (
                    <KuiTableCell
                      $align='center'
                      $stickyProps={checkboxStickyProps}
                    >
                      <KuiCheckbox
                        checked={isRowSelected(row)}
                        onChange={(nextChecked) =>
                          toggleRowSelection(row, nextChecked)
                        }
                      />
                    </KuiTableCell>
                  )}

                  {processedColumns.map(({ column, stickyProps }) => {
                    const cellContents = column.renderCell(row, {
                      hovered: hovered === rowKey,
                    })

                    const wrapInKuiText =
                      typeof cellContents === 'string' ||
                      typeof cellContents === 'number'

                    return (
                      <KuiTableCell
                        key={column.key ?? column.label}
                        $numeric={column.numeric}
                        $align={column.align}
                        $cursor={
                          !column.interactive &&
                          onRowClick &&
                          getRowIsClickable(row)
                            ? 'pointer'
                            : undefined
                        }
                        $stickyProps={stickyProps}
                        onClick={
                          !column.interactive &&
                          onRowClick &&
                          getRowIsClickable(row)
                            ? () => onRowClick(row)
                            : undefined
                        }
                      >
                        {wrapInKuiText ? (
                          <KuiText.p truncate={true}>{cellContents}</KuiText.p>
                        ) : (
                          cellContents
                        )}
                      </KuiTableCell>
                    )
                  })}
                </KuiTableRow>
              )
            })}
        </MantineTable.Tbody>
      </KuiTableRoot>

      {rows.length === 0 && !loading && (
        <KuiTableEmptyStateRoot
          $height={`calc(${minRows} * ${rowHeightMap[rowHeight]})`}
          $needsBottomBorder={!!pagination}
        >
          <KuiText.p color='hushed'>{emptyState}</KuiText.p>
        </KuiTableEmptyStateRoot>
      )}

      {bulkActions && (
        <KuiTableBulkActionBar
          pageRows={rows.length}
          totalRows={pagination?.totalRows}
          selectedRows={selectedRows}
          selectedAllAcrossPages={selectedAllAcrossPages}
          bulkActions={bulkActions}
          onSelectAllOnPage={() => {
            setSelectedRows(rows)
            setSelectedAllAcrossPages(false)
          }}
          onSelectAllAcrossPages={onSelectAllAcrossPages}
          onClearSelection={onDeselectAll}
        />
      )}

      {pagination && (
        <KuiPad size={gutterSizeMap[gutterSize]} verticalSize='xs'>
          <KuiPagination {...pagination} />
        </KuiPad>
      )}
    </KuiTableWrapperRoot>
  )

  function isRowSelected(row: TRow) {
    return selectedAllAcrossPages || selectedRowKeys.has(getRowKey(row))
  }

  function getHeaderCheckboxProps() {
    const allRowsSelected = rows.length > 0 && rows.every(isRowSelected)
    const someRowsSelected = rows.some(isRowSelected)
    const hasPages = pagination?.totalRows && pagination.totalRows > rows.length
    const selectAllLabel = hasPages
      ? `Select all ${pagination?.totalRows}`
      : 'Select all'

    return {
      checked: allRowsSelected,
      indeterminate: !allRowsSelected && someRowsSelected,
      tooltipContent: someRowsSelected ? 'Clear selection' : selectAllLabel,
      onChange: () => {
        if (someRowsSelected) {
          onDeselectAll()
        } else if (hasPages) {
          onSelectAllAcrossPages()
        } else {
          setSelectedRows(rows)
        }
      },
    }
  }

  function onDeselectAll() {
    setSelectedRows([])
    setSelectedAllAcrossPages(false)
  }

  function onSelectAllAcrossPages() {
    setSelectedRows(rows)
    setSelectedAllAcrossPages(true)
  }

  function toggleRowSelection(row: TRow, nextChecked: boolean) {
    setSelectedAllAcrossPages(false)

    setSelectedRows((prevRows) => {
      const rowKey = getRowKey(row)

      if (nextChecked) {
        return [...prevRows, row]
      }

      return prevRows.filter((r) => getRowKey(r) !== rowKey)
    })
  }

  function getFixedRowCount() {
    if (rows.length === 0) {
      return null
    }

    return Math.max(rows.length, minRows)
  }
}

type KuiTableProcessedColumn<TRow> = {
  column: KuiTableColumn<TRow>
  stickyProps?: StickyColumnProps
}

const checkBoxColumnWidth = 40

function getProcessedColumns<TRow>({
  hasCheckboxColumn,
  columns,
  isMobile,
}: {
  hasCheckboxColumn: boolean
  columns: KuiTableColumn<TRow>[]
  isMobile: boolean
}) {
  if (isMobile) {
    return {
      processedColumns: columns.map(
        (column) =>
          ({
            column,
            stickyProps: undefined,
          }) satisfies KuiTableProcessedColumn<TRow>
      ),
      checkboxStickyProps: undefined,
    }
  }

  // todo add validation for sticky columns being only at start or end (and a non sticky column being allowed to grow)
  const processedColumns: KuiTableProcessedColumn<TRow>[] = []

  const firstNonStickyColumnIndex = columns.findIndex(
    (column) => !column.sticky
  )
  const hasStickyLeftColumns = columns.some(
    (column, index) => column.sticky && index < firstNonStickyColumnIndex
  )
  const firstStickyRightColumnIndex =
    firstNonStickyColumnIndex !== -1
      ? columns.findIndex(
          (column, index) => column.sticky && index > firstNonStickyColumnIndex
        )
      : null
  const stickyRightColumns = firstStickyRightColumnIndex
    ? columns.slice(firstStickyRightColumnIndex)
    : []
  const totalStickyRightWidth = sumBy(stickyRightColumns, getColumnWidth)

  let leftOffset = hasCheckboxColumn ? checkBoxColumnWidth : 0
  let rightOffset = totalStickyRightWidth

  for (const [index, column] of columns.entries()) {
    const width = getColumnWidth(column)

    if (column.sticky && index < firstNonStickyColumnIndex) {
      processedColumns.push({
        column,
        stickyProps: {
          left: leftOffset,
          borderRight: index === firstNonStickyColumnIndex - 1,
        },
      })
      leftOffset += width
    } else if (
      column.sticky &&
      firstStickyRightColumnIndex &&
      index >= firstStickyRightColumnIndex
    ) {
      rightOffset -= width
      processedColumns.push({
        column,
        stickyProps: {
          right: rightOffset,
          borderLeft: index === firstStickyRightColumnIndex,
        },
      })
    } else {
      processedColumns.push({ column })
    }
  }

  return {
    processedColumns,
    checkboxStickyProps: {
      left: 0,
      borderRight: !hasStickyLeftColumns,
    },
  }
}

function getGridTemplateColumns({
  hasCheckboxColumn,
  processedColumns,
}: {
  hasCheckboxColumn: boolean
  processedColumns: KuiTableProcessedColumn<any>[]
}) {
  const consumerColumns = processedColumns.map(({ column, stickyProps }) => {
    const width = getColumnWidth(column)

    return column.grow === false || !!stickyProps
      ? `${width}px`
      : `minmax(${width}px, 1fr)`
  })

  if (hasCheckboxColumn) {
    return [`${checkBoxColumnWidth}px`, ...consumerColumns].join(' ')
  }

  return consumerColumns.join(' ')
}

function getColumnWidth(column: KuiTableColumn<any>): number {
  return typeof column.width === 'number'
    ? column.width
    : widthMap[column.width ?? 'm']
}

type KuiTableLoadingRowsProps = {
  processedColumns: KuiTableProcessedColumn<any>[]
  selectable: boolean
  checkboxStickyProps?: StickyColumnProps
  numRows: number
}

function KuiTableLoadingRows({
  numRows,
  processedColumns,
  selectable,
  checkboxStickyProps,
}: KuiTableLoadingRowsProps) {
  return (
    <Fragment>
      {Array.from({ length: numRows }).map((_, index) => {
        return (
          <KuiTableRow key={index}>
            {selectable && (
              <KuiTableCell
                $align='center'
                $cursor='wait'
                $stickyProps={checkboxStickyProps}
              >
                <Skeleton width='100%' height='20px' />
              </KuiTableCell>
            )}

            {processedColumns.map(({ column, stickyProps }) => (
              <KuiTableCell
                key={column.key ?? column.label}
                $numeric={column.numeric}
                $align={column.align}
                $cursor='wait'
                $stickyProps={stickyProps}
              >
                <Skeleton width='100%' height='20px' />
              </KuiTableCell>
            ))}
          </KuiTableRow>
        )
      })}
    </Fragment>
  )
}

const KuiTableBulkActionBarRoot = styled.div`
  position: absolute;
  bottom: 15px;

  left: 5%;
  width: 90%;

  z-index: 100;

  background-color: ${kuiThemeVars.colors.lightShade};
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  box-shadow: var(--mantine-shadow-sm);
`

type KuiTableBulkActionBarProps<TRow> = {
  pageRows: number
  totalRows: number | undefined
  selectedRows: TRow[]
  selectedAllAcrossPages: boolean
  bulkActions: KuiTableBulkActionsGetter<TRow>

  onSelectAllOnPage: () => void
  onSelectAllAcrossPages: () => void
  onClearSelection: () => void
}

function KuiTableBulkActionBar<TRow>({
  pageRows,
  totalRows,
  selectedRows,
  selectedAllAcrossPages,
  bulkActions,
  onSelectAllOnPage,
  onSelectAllAcrossPages,
  onClearSelection,
}: KuiTableBulkActionBarProps<TRow>) {
  const opened = selectedAllAcrossPages || selectedRows.length > 0
  const hasPages = totalRows && pageRows < totalRows

  const { actions, customComponent } = bulkActions({
    selectedRows,
    selectedAllAcrossPages,
    clearSelection: onClearSelection,
  })

  return (
    <Transition
      mounted={opened}
      transition='slide-up'
      duration={150}
      exitDuration={150}
    >
      {(styles) => (
        <KuiTableBulkActionBarRoot style={styles}>
          <KuiFlex
            justifyContent='spaceBetween'
            padding={{ size: 'xs' }}
            gapSize='xs'
          >
            <KuiMenu
              buttonProps={{
                size: 'xs',
                variant: 'transparent',
                iconType: 'chevron-down',
                iconSide: 'right',
                children: selectedAllAcrossPages
                  ? `All ${totalRows} selected`
                  : `${selectedRows.length} selected`,
              }}
              position='bottom-start'
              items={[
                {
                  label: `Select ${pageRows} on this page`,
                  onClick: onSelectAllOnPage,
                },
                ...(hasPages
                  ? [
                      {
                        label: `Select ${totalRows} across all pages`,
                        onClick: onSelectAllAcrossPages,
                      },
                    ]
                  : []),
              ]}
            />

            <KuiFlex gapSize='xs'>
              {customComponent}

              {actions.length > 0 && <KuiActionList actions={actions} />}

              <KuiButton
                size='xs'
                iconType='x'
                tooltipContent='Clear selection'
                onClick={onClearSelection}
              />
            </KuiFlex>
          </KuiFlex>
        </KuiTableBulkActionBarRoot>
      )}
    </Transition>
  )
}
